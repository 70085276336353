import React from 'react';
import { Link } from 'gatsby';

import Layout from '@src/components/layout';
import { pages } from '@src/constants/pages';
import { events } from '@src/constants/gaEvents';
import { customEvent } from '@src/helpers/gtag';
import * as ConfigValues from '@src/helpers/configValues';

const Demo = () => (
  <Layout pageName={pages.demo.name}>
    <div className="with-bottom-decoration">
      <h1 className="h-center">How it works</h1>
      <div className="subheading">
        Get a quote in minutes with our
        unique BOM tool!
      </div>
    </div>
    <section className="v-demo h-pt-40">
      <ol>
        <li>
          <div className="c-demo-info">
            <h5>Set up</h5>{' - '}
            <span>
              Tell us how many prototype assemblies you
              need and when you want them.
            </span>
          </div>
        </li>
        <li>
          <div className="c-demo-info">
            <h5>Select parts</h5>{' - '}
            <span>
            Tell us what parts you need: upload
            your bill of materials and we&apos;ll source and price your
            parts.<br />
            If we can&apos;t find a part or an exact match, we&apos;ll give you
            options. You decide how to proceed.
            </span>
          </div>
        </li>
        <li>
          <div className="c-demo-info">
            <h5>Bare Board Specification</h5>
            <span>
              <ul className="c-list">
                <li>
                  If you plan on supplying the bare PWB&apos;s for the project,
                  review ADCO&apos;s required{' '}
                  <a
                    className="c-link"
                    href="https://adco-s3-dev-public.s3.amazonaws.com/Customer+Supplied+array++Routing+guidelines.pdf"
                    target="_blank"
                  >
                    Standard Array Parameters
                  </a>{' '}
                  and move on to step #4.
                </li>
                <li>
                  If you&apos;d like ADCO to supply the bare PWB&apos;s for the project, upload
                  your board files (gerber files, PWB fab drawing, drill data) into the site,
                  and our team will get to work on a quote ASAP. Move on to step #4 to complete
                  assembly configuration details, and you will be notified with bare PWB pricing
                  within 3 business days.  You can then complete the project&apos;s order. See
                  step #6.                  
                </li>
              </ul>
            </span>
          </div>
        </li>
        <li>
          <div className="c-demo-info">
            <h5>Configure assembly</h5>{' - '}
            <span>
              Input your component counts for manufacturing cost calculations. Please note the
              PCBA will be assembled per the bill of materials, and any notes in drawings will
              not be adhered to, unless specifically requested to{' '}
              <a href={`mailto:${ConfigValues.getAdcoEmail()}`} itemProp="email">
                {ConfigValues.getAdcoEmail()}
              </a>.
            </span>
          </div>
        </li>
        <li>
          <div className="c-demo-info">
            <h5>Summary</h5>{' - '}
            <span>
            Review your quote details. You can make
            changes if you need to.
            </span>
          </div>
        </li>
        <li>
          <div className="c-demo-info">
            <h5>Purchase</h5>{' - '}
            <span>
              Enter your credit card billing and shipping info. We&apos;ll get right to work!
            </span>
          </div>
        </li>
      </ol>
      <div className="c-btn-group">
        <a
          className="c-btn c-btn--primary"
          onClick={customEvent(events.demoCreateAccount())}
          href={ConfigValues.getAppLoginUrl()}
        >
          Create an account
        </a>
        <a
          className="c-btn c-btn--secondary"
          onClick={customEvent(events.demoStartQuoting())}
          href={ConfigValues.getAppUrl()}
        >
          Continue as a guest
        </a>
      </div>
      <Link
        className="h-flex-center"
        to={pages.contact.path}
      >
        Still not sure? Get in touch.
      </Link>
      <Link
        className="h-flex-center h-mt-30 h-mb-30"
        to={pages.home.path}
      >
        ADCOProto Home
      </Link>
    </section>
  </Layout>
);

export default Demo;
